import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="ABout" keywords={[`about`, `Hollye`, `Schultz`, `Tucson`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 className="m-t-0 text-left">
            I am a designer & developer in Tucson, Arizona.
          </h2>

          <p>
            With over 12 years of experience in web design and development, I've
            learned how to make websites that users love. I have worked with
            both B2B and B2C clients in a variety of industries, from startups
            to Fortune 500 companies.{" "}
          </p>
          <p>
            After graduating art school, while designing marketing emails and
            landing pages, I taught myself how to code. This unlocked a new way
            of thinking and avenues for creativity through interaction and
            animation using HTML, SCSS, and Javascript. Now as a UI/UX designer,
            experimenting with unconventional technical solutions and a variety
            of art mediums is a part of my design process.
          </p>
          <p>
            I enjoy being involved in the end-to-end product lifecycle, from
            branding and conceptual design, to UI layouts, to collaborating on
            front-end code. Find and connect with me on{" "}
            <a
              target="_blank"
              href="https://www.linkedin.com/in/hollyeschultz/"
            >
              LinkedIn
            </a>
            .
          </p>

          <form name="contact" method="POST" data-netlify="true" action="/sent">
            <p>
              <label>
                Leave a message <textarea name="comment"></textarea>
              </label>
            </p>
            <p>
              <label>
                Email <input type="email" name="email" />
              </label>
            </p>
            <p>
              <button class="primary" type="submit">
                SEND
              </button>
            </p>
            <input type="hidden" name="form-name" value="contact"></input>
          </form>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
